import React, { RefObject } from 'react'

import {
  Modal,
  Pagination,
  Stack,
} from '@shopify/polaris'

import comgate1 from '../assets/comgate01.jpg'
import comgate2 from '../assets/comgate02.jpg'
import comgate3 from '../assets/comgate03.jpg'
import comgate4 from '../assets/comgate04.jpg'
import { getTranslation } from '../util/getTranslation'

interface Props {
  isOpen: boolean;
  activatorRef: RefObject<HTMLElement>;
  toggleModal: () => void;
}

const stepImages = [comgate1, comgate2, comgate3, comgate4]

export function ComGateCredentialsGuide(props: Props) {
  const [activeStep, setActiveStep] = React.useState(0)


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Modal
      activator={props.activatorRef}
      open={props.isOpen}
      onClose={props.toggleModal}
      title={getStepExplanation(activeStep)}
      large
      primaryAction={{
        content: getTranslation('Zavřít', 'Close'),
        onAction: props.toggleModal,
      }}
      footer={
        <Stack distribution="trailing">
          <Pagination
            hasNext={activeStep < 3}
            hasPrevious={activeStep > 0}
            onNext={handleNext}
            onPrevious={handleBack}
            label={getTranslation(
              'Přesun mezi kroky návodu',
              'Navigation for steps of the guide',
            )}
          />
        </Stack>
      }
    >
      <Modal.Section>
        <img src={stepImages[activeStep]} alt="" />
      </Modal.Section>
    </Modal>
  )
}

function getStepExplanation(stepIdx: number) {
  switch (stepIdx) {
    case 0:
      return getTranslation(
        'Zvolte nastavení obchodu, který chcete použít',
        'Select store settings that you want to use',
      )
    case 1:
      return getTranslation(
        'Zvolte záložku "Propojení obchodu"',
        'Select "Store connection" tab',
      )
    case 2:
      return getTranslation(
        'Klikněte na identifikátor propojení obchodu',
        'Click on the store connection identifier',
      )
    case 3:
      return getTranslation(
        'Zkopírujte zvýrazněné údaje do formuláře',
        'Copy highlighted details into the form',
      )
    default:
      return null
  }
}

export default ComGateCredentialsGuide
